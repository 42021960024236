import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative h-screen" }
const _hoisted_2 = { class: "font-semibold text-20px mr-4 text-black-lp-200 title-layout flex flex-row align-middle justify-center" }
const _hoisted_3 = {
  class: "mr-8",
  style: {"align-self":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ModalFormBulkUpdateRelease = _resolveComponent("ModalFormBulkUpdateRelease")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "items-center" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode("span", _hoisted_3, _toDisplayString(_ctx.Title), 1),
            _createVNode(_component_Search, {
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.searchValue,
              placeholder: "Cari"
            }, null, 8, ["onInput", "onClear", "value"]),
            _createVNode(_component_time_range_picker, {
              onSelect: _ctx.setDateRange,
              default: "7 hari terakhir",
              showOptions: "custom, hari ini, 7 hari terakhir, 14 hari terakhir",
              minDate: _ctx.minDate,
              maxDate: _ctx.maxDate,
              onDayClick: _ctx.setDay,
              isOptionDisabled: !_ctx.isStartDate,
              onCancelRangePick: _ctx.cancelRangePick,
              isMounted: false
            }, null, 8, ["onSelect", "minDate", "maxDate", "onDayClick", "isOptionDisabled", "onCancelRangePick"])
          ]),
          _createVNode(_component_lp_button, {
            onClick: _ctx.goToUpdate,
            title: "Update Release",
            textColor: "white"
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }),
      (!_ctx.isLoading && !_ctx.releaseList.data.length)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            data: "Release"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.releaseList.data,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            paginationStyle: "v3",
            onRequest: _ctx.fetchReleaseList,
            errorCause: _ctx.errorCause,
            isDetailAble: _ctx.ableToDetail,
            onClick: _ctx.goToDetail
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "isDetailAble", "onClick"]))
    ]),
    _createVNode(_component_ModalFormBulkUpdateRelease, {
      modelValue: _ctx.showFormUpdate,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showFormUpdate = $event)),
      onRequest: _ctx.fetchReleaseList
    }, null, 8, ["modelValue", "onRequest"])
  ], 64))
}