
/* eslint-disable @typescript-eslint/camelcase */
import { ReleaseController } from "@/app/ui/controllers/ReleaseController";
import { mixins, Options } from "vue-class-component";
import DownloadCsv from "@/app/ui/views/out-going-shipment/out-going-mixin";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { downloadFile, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { BulkUpdateReleaseRequest } from "@/data/payload/api/BulkUpdateReleaseRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";

@Options({
  components: {
    UploadFile,
  },
  emits: [
    "request"
  ]
})
export default class BulkUpdateRelease extends mixins(DownloadCsv) {
  mounted() {
    this.onReset();
  }
  // Handle File
  archiveFile = "";
  isClearFile = false;
  statusReset = false;

  get showFormUpdate() {
    return ReleaseController.showFormUpdate;
  }
  get isDisabled() {
    return !this.archiveFile;
  }
  onReset() {
    ReleaseController.setShowFormUpdate(false);
    this.statusReset = true;
    this.archiveFile = "";
    this.isClearFile = true;
  }
  onDownloadCsvFailed(listSttFailed: any) {
    listSttFailed = listSttFailed.map((key: any) => ({
      stt_no: key.stt_or_bag_no,
    }));
    this.downloadCsvFailedStt({
      fileName: "Release_stt_failed.csv",
      listStt: listSttFailed,
    });
    MainAppController.closeMessageModal();
  }
  popupConfirmation() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "are-you-sure",
        title: "Konfirmasi Pembuatan",
        message: "Pastikan kembali, seluruh detail STT sudah benar dan sesuai.",
        textSuccess: "Proses",
        textCancel: "Kembali",
        onClose: () => {
          MainAppController.closeMessageModal();
          ReleaseController.setShowFormUpdate(true);
        },
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.onAdd();
        },
      })
    );
  }
  onMappingResponse(resp: ResponsePayloadV2) {
    if (resp.data.totalSTTSuccess > 0 && resp.data.totalSTTFailed > 0) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          image: "image-modal-warning",
          title: "Pembaruan Berhasil !",
          message:
            "Beberapa status STT berhasil diperbarui Release, namun terdapat kegagalan pada beberapa STT. Silahkan hapus STT bermasalah untuk melanjutkan pembaruan.",
          textSuccess: "Download",
          textCancel: "OK",
          totalSuccess: resp.data.totalSTTSuccess,
          totalFailed: resp.data.totalSTTFailed,
          onSubmit: () => {
            this.onDownloadCsvFailed(resp.data.listSTTFailed);
            this.onReset();
            this.$emit("request");
          },
          onClose: () => {
            MainAppController.closeMessageModal();
            this.onReset();
            this.$emit("request");
          },
        })
      );
    } else if (resp.data.totalSTTFailed > 0 || resp.data.listSTTFailed.length) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          image: "image-modal-failed",
          title: "Pembuatan Bulk Release Gagal !",
          message: "Status Release gagal diperbarui.",
          textSuccess: "Download",
          textCancel: "OK",
          totalFailed: resp.data.totalSTTFailed,
          onSubmit: () => {
            this.onDownloadCsvFailed(resp.data.listSTTFailed);
            this.onReset();
            this.$emit("request");
          },
          onClose: () => {
            MainAppController.closeMessageModal();
            this.onReset();
            this.$emit("request");
          },
        })
      );
    } else {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          image: "image-modal-success",
          title: "Pembuatan Bulk Release Berhasil !",
          message: "Bulk Release berhasil dibuat.",
          textSuccess: "OK",
          onSubmit: () => {
            MainAppController.closeMessageModal();
            this.onReset();
            this.$emit("request");
          },
        })
      );
    }
  }
  async onAdd() {
    MainAppController.closeErrorMessage();
    try {
      MainAppController.showLoading();
      const resp = await ReleaseController.updateBulkUpdateRelease(
        new BulkUpdateReleaseRequest({ attachment: this.archiveFile })
      );
      this.onMappingResponse(resp);
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Pembuatan Bulk Release Gagal !", () =>
          this.onAdd()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
  downloadTemplate(url: string, fileName: string, extension: string) {
    downloadFile(url, fileName, extension);
  }
}
