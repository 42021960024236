
import {
  formatDate,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import { debounce } from "lodash";
import { Vue, Options } from "vue-class-component";
import { ReleaseController } from "@/app/ui/controllers/ReleaseController";
import {
  ReleaseData,
  ReleaseEntities,
  ReleaseListRequest,
} from "@/domain/entities/Release";
import { FlagsPermissionOutgoingShipmentRelease } from "@/feature-flags/flags-out-going-shipment-release";
import { Pagination } from "@/domain/entities/Pagination";
import ModalFormBulkUpdateRelease from "./components/bulk-upload-release.vue";

@Options({
  components: {
    ModalFormBulkUpdateRelease,
  },
})
export default class ReleaseList extends Vue {
  mounted() {
    this.setReset()
    this.fetchReleaseList()
  }
  get Title() {
    return this.$route.name;
  }
  get ableToDetail() {
    return FlagsPermissionOutgoingShipmentRelease.permission_release_detail_view.isEnabled();
  }
  goToDetail(item: ReleaseData) {
    // function to detail page
    this.$router.push(`/release/${item.releaseNo}`);
  }
  goToUpdate() {
    ReleaseController.setShowFormUpdate(true);
  }
  // filter date
  get periodeStart() {
    return ReleaseController.periodeStart;
  }

  get periodeEnd() {
    return ReleaseController.periodeEnd;
  }

  setReset() {
    ReleaseController.setSearch("");
    ReleaseController.setPeriodeStart(new Date(new Date(new Date().setDate(new Date().getDate() - 6))));
    ReleaseController.setPeriodeEnd(new Date(new Date(new Date().setDate(new Date().getDate()))));
  }

  // time-range-picker
  minDate: any = null;
  maxDate: any = null;
  tempPeriodeStart: any = null;
  tempPeriodeEnd: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempPeriodeStart = this.periodeStart;
      this.tempPeriodeEnd = this.periodeEnd;
      ReleaseController.setPeriodeStart(null);
      ReleaseController.setPeriodeEnd(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 13)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 13)
      );
      this.isStartDate = false;
    } else {
      this.periodeEnd && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      ReleaseController.setPeriodeStart(this.tempPeriodeStart);
      ReleaseController.setPeriodeEnd(this.tempPeriodeEnd);
      this.tempPeriodeStart = null;
      this.tempPeriodeEnd = null;
      this.isStartDate = true;
    }
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    ReleaseController.setPeriodeStart(value[0]);
    ReleaseController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchReleaseList();
  }

  // filter search
  get searchValue() {
    return ReleaseController.filter.search;
  }

  onSearch = debounce((val: string) => {
    ReleaseController.setSearch(val);
    this.fetchReleaseList();
  }, 250);

  clearSearch() {
    ReleaseController.setSearch("");
    this.fetchReleaseList();
  }

  // loading
  isLoading = false;
  // error cause
  errorCause = "";
  isError = false;

  get filter() {
    return ReleaseController.filter;
  }

  async fetchReleaseList() {
    // hit endpoint get list Release
    try {
      this.isLoading = true;
      this.isError = false;
      this.errorCause = "";
      const res = await ReleaseController.getReleaseList(
        new ReleaseListRequest({
          page: this.pagination.page,
          limit: this.pagination.limit,
          search: encodeURIComponent(this.filter.search),
          startDate: this.periodeStart?.toISOString().slice(0, 10),
          endDate: this.periodeEnd?.toISOString().slice(0, 10),
        })
      );
      this.onMappingReleaseList(res);
    } catch (error) {
      this.isError = true;
      this.errorCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
      this.isError = false;
    }
  }

  onMappingReleaseList(data: ReleaseEntities) {
    this.releaseList = data;
  }

  releaseList = new ReleaseEntities(new Pagination(1, 20), []);
  get pagination() {
    return this.releaseList.pagination;
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-1/4 text-left whitespace-no-wrap",
      render: (item: any, index: any) => {
        return `<div class="overflow-ellipsis text-left">${
          index + 1 + this.pagination.limit * (this.pagination.page - 1)
        }</div>`;
      },
    },
    {
      name: "No. Release",
      styleHead: "w-2/4 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.releaseNo}</div>`;
      },
    },
    {
      name: "Total Berat",
      styleHead: "w-2/4 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.releaseTotalWeight} Kg</div>`;
      },
    },
    {
      name: "Total Koli",
      styleHead: "w-2/4 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-left">${item.releaseTotalPieces}</div>`;
      },
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-2/4 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${formatDate(
          item.releaseCreatedAt
        )}</div>
              <div class="capitalize text-gray-lp-500 text-12px">${
                item.releaseCreatedName
              }</div>`;
      },
    },
  ];

  get showFormUpdate() {
    return ReleaseController.showFormUpdate;
  }
}
